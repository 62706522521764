'use client'

import { add, throttle } from 'lodash'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { addPlayEvent } from '@/lib/play/addPlayEvent'
import { fetchPost } from '@/lib/post/fetchPost'
import { fetchPostsForAccount } from '@/lib/post/fetchPostsForAccount'
import { fetchPostsForHub } from '@/lib/post/fetchPostsForHub'
import { fetchRecommendedReleasesForRelease } from '@/lib/release/fetchRecommendedReleasesForRelease'
import { fetchRelease } from '@/lib/release/fetchRelease'
import { fetchReleasesCollectedByAccount } from '@/lib/release/fetchReleasesCollectedByAccount'
import { fetchReleasesForHub } from '@/lib/release/fetchReleasesForHub'
import { fetchReleasesPublishedByAccount } from '@/lib/release/fetchReleasesPublishedByAccount'
import {
  PaginationProps,
  PlayEventType,
  PlayType,
  QueueItem,
  QueueItemQueueType,
  Release,
} from '@/lib/types'
import { logEvent } from '@/lib/utils/event'
import { getImage } from '@/lib/utils/imgix'
import { randomIntFromInterval } from '@/lib/utils/randomIntFromInterval'
import shuffleArray from '@/lib/utils/shuffleArray'
import { detectMedDown } from '@/lib/utils/useScreenWidth'
import Wallet from './WalletContext'

type PlayNextQueueItemParams = {
  bypassRecommendations?: boolean
  isAutoPlay?: boolean
}

interface AudioContextValues {
  queue: QueueItem[]
  history: QueueItem[]
  addTrackToQueue: (
    release: Release,
    trackNumber: number,
    playType: PlayType,
    type?: QueueItemQueueType,
  ) => void
  addReleaseToQueue: (
    release: Release,
    playType: PlayType,
    type?: QueueItemQueueType,
    startAt?: number | undefined,
  ) => void
  addMultipleReleasesToQueue: (
    releases: Release[],
    playType: PlayType,
    type?: QueueItemQueueType,
    offset?: number,
    bypassRecommendations?: boolean,
  ) => void
  playNextQueueItem: (playNextQueueItemParams?: PlayNextQueueItemParams) => void
  playPreviousQueueItem: () => void
  playQueueItemAtIndex: (
    release: Release,
    index: number,
    inEmbed?: boolean,
  ) => void
  playTrack: (release: Release, trackNumber: number) => void
  playRelease: (
    release: Release,
    startAt?: number | undefined,
    bypassRecommendations?: boolean,
  ) => void
  pauseResume: (forcePause?: boolean) => void
  clearQueue: () => void
  setCurrentTime: (time: number) => void
  moveQueueItem: (from: number, to: number) => void
  handleSeek: (value: number) => void
  audioPlayerRef: React.RefObject<HTMLAudioElement>
  activeQueueItem: () => QueueItem | undefined
  currentTime: number
  duration: number
  isPlaying: boolean
  setIsPlaying?: (arg: boolean) => void
  drawerOpen: boolean
  setDrawerOpen: (arg: boolean) => void
  handleDrawerToggle: () => void
  activeIndex: number
  fetchInitialQueueFromNinasPicks: () => void
  fetchInitialQueueFromHub: (hub: string) => void
  fetchInitialQueueFromProfile: (profile: string) => void
  fetchInitialQueueForRelease: (release: string) => void
  fetchInitialQueueForPost: (post: string) => void
  activeItem: QueueItem | null
}

const AudioContext = createContext<AudioContextValues>({
  queue: [] as QueueItem[],
  history: [] as QueueItem[],
  addTrackToQueue: () => undefined,
  addReleaseToQueue: () => undefined,
  addMultipleReleasesToQueue: () => undefined,
  playNextQueueItem: () => undefined,
  playPreviousQueueItem: () => undefined,
  playQueueItemAtIndex: () => undefined,
  playTrack: () => undefined,
  playRelease: () => undefined,
  clearQueue: () => undefined,
  moveQueueItem: () => undefined,
  pauseResume: () => undefined,
  audioPlayerRef: {} as React.RefObject<HTMLAudioElement>,
  activeQueueItem: () => undefined,
  currentTime: 0,
  duration: 0,
  isPlaying: false,
  handleSeek: () => undefined,
  setCurrentTime: () => undefined,
  drawerOpen: false,
  setDrawerOpen: () => undefined,
  handleDrawerToggle: () => undefined,
  activeIndex: -1,
  fetchInitialQueueFromNinasPicks: () => undefined,
  fetchInitialQueueFromHub: () => undefined,
  fetchInitialQueueFromProfile: () => undefined,
  fetchInitialQueueForRelease: () => undefined,
  fetchInitialQueueForPost: () => undefined,
  activeItem: null,
})

const AudioContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [history, setHistory] = useState([] as QueueItem[])
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [activeItem, setActiveItem] = useState(null as QueueItem | null)

  const [broadcastChannel, setBroadcastChannel] =
    useState<BroadcastChannel | null>(null)

  const audioPlayerRef = useRef<HTMLAudioElement | null>(null)
  const intervalRef = useRef<NodeJS.Timer>()
  const isMedDown = detectMedDown()
  const activeIndexRef = useRef<number>(-1)
  const queueRef = useRef<QueueItem[]>([])
  const { wallet } = useContext(Wallet.Context)
  const walletRef = useRef(wallet)

  useEffect(() => {
    walletRef.current = wallet
  }, [wallet])

  useEffect(() => {
    audioPlayerRef.current = new Audio()
    audioPlayerRef.current.volume = 0.8
    audioPlayerRef.current.addEventListener('error', (e) => {
      console.log('e.error', e.error)
      if (e?.error?.code === 'MEDIA_ERR_SRC_NOT_SUPPORTED') {
        if (
          activeQueueItem() &&
          audioPlayerRef.current?.src.includes('gateway.irys.xyz')
        ) {
          const uri = activeQueueItem()!.release.metadata.properties.files[
            activeQueueItem()!.trackNumber - 1
          ].uri.replace('gateway.irys.xyz', 'ar-io.net')

          play(uri)
        } else if (
          activeQueueItem() &&
          audioPlayerRef.current?.src.includes('ar-io.net')
        ) {
          const uri = activeQueueItem()!.release.metadata.properties.files[
            activeQueueItem()!.trackNumber - 1
          ].uri.replace('ar-io.net', 'arweave.net')

          play(uri)
        }
      }
    })

    const pauseEventListenerCallback = () => {
      logEvent('track_pause', 'engagement', walletRef.current, {
        publicKey: activeQueueItem()!.release.publicKey,
        trackName: activeQueueItem()!.release.metadata.properties.files.find(
          (file) => file.track === activeQueueItem()!.trackNumber,
        )?.track_title,
      })

      setIsPlaying(false)
    }

    audioPlayerRef.current.addEventListener('pause', pauseEventListenerCallback)

    const playEventListenerCallback = () => {
      logEvent('track_play', 'engagement', walletRef.current, {
        publicKey: activeQueueItem()!.release.publicKey,
        trackName: activeQueueItem()!.release.metadata.properties.files.find(
          (file) => file.track === activeQueueItem()!.trackNumber,
        )?.track_title,
      })
      setIsPlaying(true)
    }

    audioPlayerRef.current.addEventListener('play', playEventListenerCallback)

    if ('BroadcastChannel' in self) {
      const bc = new BroadcastChannel('nina_channel')
      bc.onmessage = function (ev) {
        if (ev.data.type === 'updateTabPlaying') {
          pauseResume(true)
        }
      }
      setBroadcastChannel(bc)
    }

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  const updateMediaSession = () => {
    const actionHandlers = [
      ['play', pauseResume],
      ['pause', pauseResume],
      ['nexttrack', playNextQueueItem],
      ['previoustrack', playPreviousQueueItem],
    ]

    for (const [action, handler] of actionHandlers) {
      try {
        navigator.mediaSession.setActionHandler(
          action as MediaSessionAction,
          handler as unknown as MediaSessionActionHandler,
        )
      } catch (error) {
        console.warn(
          `The media session action "${action}" is not supported yet.`,
        )
      }
    }

    if (activeQueueItem() && 'mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title:
          activeQueueItem()?.release.metadata.properties.files.find(
            (file) => file.track === activeQueueItem()?.trackNumber,
          )?.track_title ||
          activeQueueItem()?.release.metadata.properties.title,
        artist:
          activeQueueItem()?.release.metadata.properties.artist ||
          activeQueueItem()?.release.hub?.data.displayName ||
          activeQueueItem()?.release.publisherAccount.displayName,
        artwork: [
          {
            src:
              getImage(activeQueueItem()!.release.metadata.image!, 256) || '',
            sizes: '256x256',
            type: 'image/webp',
          },
        ],
      })
    }
  }

  const fetchInitialQueueFromHub = async (hub: string) => {
    const releasesToQueue: Release[] = []

    const { releases } = await fetchReleasesForHub({
      publicKey: hub,
      pagination: {
        limit: 25,
        offset: 0,
        sort: 'desc',
      } as PaginationProps,
      withAccountData: false,
    })

    if (releases?.length > 0) {
      releases.forEach((release: Release) => {
        releasesToQueue.push(release)
      })
    }

    const { posts } = await fetchPostsForHub({
      publicKey: hub,
      pagination: {
        limit: 25,
        offset: 0,
        sort: 'desc',
      } as PaginationProps,
      withAccountData: false,
    })

    if (posts?.length > 0) {
      for await (const post of posts) {
        for await (const block of post.data.blocks) {
          if (block.type === 'release' || block.type === 'featuredRelease') {
            const { release } = await fetchRelease(block.data, false)

            if (
              !releasesToQueue.find(
                (item) => item.publicKey === release.publicKey,
              )
            ) {
              releasesToQueue.push(release)
            }
          }
        }
      }
    }

    if (releasesToQueue.length > 0) {
      addMultipleReleasesToQueue(
        releasesToQueue,
        PlayType.Initial,
        QueueItemQueueType.User,
      )
    } else {
      fetchInitialQueueFromNinasPicks()
    }
  }

  const fetchInitialQueueFromProfile = async (profile: string) => {
    const releasesToQueue: Release[] = []

    const { releases } = await fetchReleasesPublishedByAccount({
      publicKey: profile,
      pagination: {
        limit: 25,
        offset: 0,
        sort: 'desc',
      } as PaginationProps,
      withAccountData: false,
    })

    if (releases?.length > 0) {
      releases.forEach((release: Release) => {
        releasesToQueue.push(release)
      })
    }

    const { collected } = await fetchReleasesCollectedByAccount({
      publicKey: profile,
      pagination: {
        limit: 25,
        offset: 0,
        sort: 'desc',
      } as PaginationProps,
      withAccountData: false,
    })

    if (collected?.length > 0) {
      collected.forEach((release: Release) => {
        if (
          !releasesToQueue.find((item) => item.publicKey === release.publicKey)
        ) {
          releasesToQueue.push(release)
        }
      })
    }

    const { posts } = await fetchPostsForAccount({
      publicKey: profile,
      pagination: {
        limit: 25,
        offset: 0,
        sort: 'desc',
      } as PaginationProps,
      withAccountData: false,
    })

    if (posts?.length > 0) {
      for await (const post of posts) {
        for await (const block of post.data.blocks) {
          if (block.type === 'release' || block.type === 'featuredRelease') {
            const { release } = await fetchRelease(block.data, false)

            if (
              !releasesToQueue.find(
                (item) => item.publicKey === release.publicKey,
              )
            ) {
              releasesToQueue.push(release)
            }
          }
        }
      }
    }

    if (releasesToQueue.length > 0) {
      addMultipleReleasesToQueue(
        releasesToQueue,
        PlayType.Initial,
        QueueItemQueueType.User,
      )
    } else {
      fetchInitialQueueFromNinasPicks()
    }
  }

  const fetchInitialQueueForRelease = async (release: string) => {
    const { release: fetchedRelease } = await fetchRelease(release, false)

    if (fetchedRelease) {
      addMultipleReleasesToQueue(
        [fetchedRelease],
        PlayType.Initial,
        QueueItemQueueType.User,
      )
    }
  }

  const fetchInitialQueueForPost = async (post: string) => {
    try {
      const releasesToQueue: Release[] = []
      const { post: fetchedPost } = await fetchPost(post, false)
      for await (const block of fetchedPost?.data?.blocks) {
        if (block.type === 'featuredRelease') {
          releasesToQueue.push(block.data)
        }

        if (block.type === 'release') {
          for await (const entry of block.data) {
            const { release } = await fetchRelease(entry.publicKey, false)
            releasesToQueue.push(release)
          }
        }
      }

      if (releasesToQueue.length > 0) {
        addMultipleReleasesToQueue(
          releasesToQueue,
          PlayType.Initial,
          QueueItemQueueType.User,
        )
      } else {
        fetchInitialQueueFromNinasPicks()
      }
    } catch (error) {
      console.warn(error)
    }
  }

  const fetchInitialQueueFromNinasPicks = async () => {
    const defaultHub =
      process.env.SOLANA_RPC_CLUSTER_ENV === 'devnet'
        ? 'the-magician'
        : 'ninas-picks'

    const { releases } = await fetchReleasesForHub({
      publicKey: defaultHub,
      pagination: {
        limit: 15,
        random: true,
      } as PaginationProps,
      withAccountData: false,
    })

    shuffleArray(releases)

    // addMultipleReleasesToQueue(releases, PlayType.Initial)
    addRandomTrackFromMultipleReleasesToQueue(
      releases.slice(0, 24),
      QueueItemQueueType.Recommendation,
    )
  }

  const loadRecommendedForRelease = async (release: Release) => {
    const recommendations = await fetchRecommendedReleasesForRelease({
      publicKey: release.publicKey,
    })

    const existingQueuePublicKeys = queueRef.current.map(
      (item) => item.release.publicKey,
    )

    const filteredRecommendations = recommendations.filter(
      (item) => !existingQueuePublicKeys.includes(item.publicKey),
    ) as Release[]

    if (filteredRecommendations.length > 0) {
      for (const rec of filteredRecommendations) {
        addRandomTrackFromReleaseToQueue(rec, QueueItemQueueType.Recommendation)
      }
    }
  }

  const addRandomTrackFromMultipleReleasesToQueue = async (
    releases: Release[],
    type: QueueItemQueueType,
  ) => {
    for (const release of releases) {
      addRandomTrackFromReleaseToQueue(release, type)
    }

    if (!activeQueueItem()) {
      activeIndexRef.current = 0
      const uri = releases[0].metadata.properties.files[0].uri
        .replace('www.', '')
        .replace('arweave.net', 'gateway.irys.xyz')

      if (audioPlayerRef.current) {
        audioPlayerRef.current.src = uri
      }

      handleDurationUpdate()
    }
  }

  const addRandomTrackFromReleaseToQueue = async (
    release: Release,
    type: QueueItemQueueType,
  ) => {
    const index = randomIntFromInterval(
      0,
      release.metadata.properties.files.length - 1,
    )

    addTrackToQueue(
      release,
      index + 1,
      queueRef.current.length === 0 ? PlayType.Initial : PlayType.Later,
      type,
    )
  }

  const activeQueueItem = useCallback(() => {
    if (queueRef.current.length > 0) {
      setActiveItem(queueRef.current[activeIndexRef.current])

      return queueRef.current[activeIndexRef.current]
    }

    setActiveItem(null)

    return undefined
  }, [queueRef.current, activeIndexRef.current])

  const startTimer = () => {
    clearInterval(intervalRef.current)
    intervalRef.current = setInterval(() => {
      if (
        audioPlayerRef.current &&
        audioPlayerRef.current?.currentTime &&
        (audioPlayerRef.current?.duration ||
          activeQueueItem()?.release.metadata.properties.files[
            activeQueueItem()!.trackNumber - 1
          ]?.duration)
      ) {
        if (
          audioPlayerRef.current.currentTime >= audioPlayerRef.current?.duration
        ) {
          logEvent('track_play_reached_end_of_track', 'engagement', wallet, {
            publicKey: activeQueueItem()!.release.publicKey,
            trackName:
              activeQueueItem()!.release.metadata.properties.files.find(
                (file) => file.track === activeQueueItem()!.trackNumber,
              )?.track_title,
          })
          addPlayEvent(
            activeQueueItem()!.release.publicKey,
            activeQueueItem()!.trackNumber,
            PlayEventType.PlayReachedEnd,
          )
          if (queueRef.current.length > activeIndexRef.current + 1) {
            playNextQueueItem({ isAutoPlay: true })
          } else {
            clearInterval(intervalRef.current)
            setCurrentTime(0)
          }
        } else {
          setDuration(audioPlayerRef.current.duration)
          setCurrentTime(Math.ceil(audioPlayerRef.current.currentTime))
        }
      } else {
        if (!duration) {
          setDuration(
            activeQueueItem()?.release.metadata.properties.files[
              activeQueueItem()!.trackNumber - 1
            ]?.duration || 0,
          )
        }
      }
    }, 300)
  }

  const addTrackToQueue = (
    release: Release,
    trackNumber: number,
    playType: PlayType,
    type: QueueItemQueueType = QueueItemQueueType.User,
  ) => {
    const queueItem = {
      release,
      trackNumber,
      type,
    }

    if (playType === PlayType.Initial) {
      queueRef.current = [queueItem]
      activeIndexRef.current = 0
      const uri = release.metadata.properties.files[trackNumber - 1].uri
        .replace('www.', '')
        .replace('arweave.net', 'gateway.irys.xyz')

      if (audioPlayerRef.current) {
        audioPlayerRef.current.src = uri
      }
    } else if (playType === PlayType.Now) {
      queueRef.current = [
        ...queueRef.current.slice(0, activeIndexRef.current),
        queueItem,
        ...queueRef.current.slice(activeIndexRef.current + 1),
      ]
    } else if (playType === PlayType.Next) {
      queueRef.current = [
        ...queueRef.current.slice(0, activeIndexRef.current + 1),
        queueItem,
        ...queueRef.current.slice(activeIndexRef.current + 1),
      ]
    } else if (playType === PlayType.Later) {
      queueRef.current = [...queueRef.current, queueItem]
    }
  }

  const addReleaseToQueue = (
    release: Release,
    playType: PlayType,
    type: QueueItemQueueType = QueueItemQueueType.User,
    startAtTrack?: number | undefined,
    offset?: number | undefined,
  ) => {
    let tracks = release?.metadata.properties.files.map((file) => ({
      release,
      trackNumber: file.track,
      type,
    }))
    tracks = Array.from([...tracks])

    if (startAtTrack && tracks.length > 1) {
      //@ts-ignore
      tracks = tracks.toSpliced(0, startAtTrack)
    }

    const firstTrackAdded = tracks[0]

    if (offset) {
      queueRef.current = [
        ...queueRef.current.slice(0, offset),
        ...tracks,
        ...queueRef.current.slice(offset),
      ]
    } else if (playType === PlayType.Now) {
      queueRef.current = [
        ...queueRef.current.slice(0, activeIndexRef.current),
        ...tracks,
        ...queueRef.current.slice(activeIndexRef.current + 1),
      ]
    } else if (playType === PlayType.Next) {
      queueRef.current = [
        ...queueRef.current.slice(0, activeIndexRef.current + 1),
        ...tracks,
        ...queueRef.current.slice(activeIndexRef.current + 1),
      ]
    } else if (playType === PlayType.Later) {
      queueRef.current = [...queueRef.current, ...tracks]
    }

    return queueRef.current.indexOf(firstTrackAdded) // return the index of the first track added
  }

  const addMultipleReleasesToQueue = (
    releases: Release[],
    playType: PlayType,
    type: QueueItemQueueType = QueueItemQueueType.User,
    offset = 0,
    bypassRecommendations = false,
  ) => {
    let totalTracks = offset
    const firstRelease = releases[0] as Release

    if (playType === PlayType.Initial) {
      addReleaseToQueue(firstRelease, PlayType.Now, type)
      activeIndexRef.current = 0
      const uri = firstRelease.metadata.properties.files[0].uri
        .replace('www.', '')
        .replace('arweave.net', 'gateway.irys.xyz')

      if (audioPlayerRef.current) {
        audioPlayerRef.current.src = uri
      }

      loadRecommendedForRelease(firstRelease)
    } else if (playType === PlayType.Now) {
      playRelease(firstRelease, 0, bypassRecommendations)
    } else {
      addReleaseToQueue(firstRelease, playType, type)
    }

    totalTracks = firstRelease.metadata.properties.files.length
    const reversedArray = Array.from(releases.slice(1, releases.length))
    reversedArray.forEach((release) => {
      if (playType === PlayType.Now || playType === PlayType.Next) {
        addReleaseToQueue(release, PlayType.Next, type, undefined, totalTracks)
        totalTracks += release.metadata.properties.files.length
      } else {
        addReleaseToQueue(release, PlayType.Later, type)
      }
    })

    if (activeIndexRef.current === -1) {
      activeIndexRef.current = 0
    }

    handleDurationUpdate()
  }

  const playNextQueueItem = (
    playNextQueueItemParams?: PlayNextQueueItemParams,
  ) => {
    let bypassRecommendations = false
    let isAutoPlay = false
    if (playNextQueueItemParams) {
      bypassRecommendations =
        playNextQueueItemParams.bypassRecommendations || false
      isAutoPlay = playNextQueueItemParams.isAutoPlay || false
    }

    if (
      queueRef.current.length > activeIndexRef.current + 1 &&
      audioPlayerRef.current
    ) {
      setHistory([queueRef.current[activeIndexRef.current], ...history])

      const { release, trackNumber } =
        queueRef.current[activeIndexRef.current + 1]

      const file = release.metadata.properties.files
        .find((file) => file.track === trackNumber)
        ?.uri.replace('www.', '')
        .replace('arweave.net', 'gateway.irys.xyz')

      if (file) {
        if (!isAutoPlay) {
          addPlayEvent(
            activeQueueItem()!.release.publicKey,
            activeQueueItem()!.trackNumber,
            PlayEventType.Next,
          )
        }
        activeIndexRef.current = activeIndexRef.current + 1
        play(file)

        if (!bypassRecommendations) {
          loadRecommendedForRelease(release)
        }
      }
    }
  }

  const playPreviousQueueItem = () => {
    if (queueRef.current.length > 0 && activeIndexRef.current > 0) {
      const { release, trackNumber } =
        queueRef.current[activeIndexRef.current - 1]

      const file = release.metadata.properties.files
        .find((file) => file.track === trackNumber)
        ?.uri.replace('www.', '')
        .replace('arweave.net', 'gateway.irys.xyz')

      if (file) {
        addPlayEvent(
          activeQueueItem()!.release.publicKey,
          activeQueueItem()!.trackNumber,
          PlayEventType.Previous,
        )
        activeIndexRef.current = activeIndexRef.current - 1
        play(file)
      }
    }
  }

  // const removeQueueItem = (index: number) => {
  //   const newQueue = [...queue]
  //   newQueue.splice(index, 1)
  //   setQueue(newQueue)
  // }

  const playQueueItemAtIndex = (
    release: Release,
    index: number,
    inEmbed?: boolean,
  ) => {
    if (activeQueueItem()) {
      setHistory([activeQueueItem()!, ...history])
    }

    const uri = release.metadata.properties.files[0].uri
      .replace('www.', '')
      .replace('arweave.net', 'gateway.irys.xyz')

    if (!inEmbed) {
      //this MOVES the item to the top of the queue (hitting play and reorder)
      moveQueueItem(index, activeIndexRef.current + 1)
      activeIndexRef.current = activeIndexRef.current + 1
    } else {
      //this PLAYS the item at the index (hitting play)
      activeIndexRef.current = index
    }

    play(uri)
  }

  const clearQueue = () => {
    const activeTrack = activeQueueItem()

    if (activeTrack) {
      queueRef.current = [{ ...activeTrack }]
    } else {
      queueRef.current = []
    }

    activeIndexRef.current = 0
  }

  const playTrack = (release: Release, trackNumber: number) => {
    const uri = release.metadata.properties.files[trackNumber - 1].uri
      .replace('www.', '')
      .replace('arweave.net', 'gateway.irys.xyz')
    const queueSizeBefore = queueRef.current.length
    addTrackToQueue(release, trackNumber, PlayType.Now, QueueItemQueueType.User)
    activeIndexRef.current = queueSizeBefore
    play(uri)
  }

  const playRelease = (
    release: Release,
    startAt = 0,
    bypassRecommendations = false,
  ) => {
    if (
      activeQueueItem() &&
      activeQueueItem()!.release.publicKey !== release.publicKey
    ) {
      setHistory([activeQueueItem()!, ...history])
    }

    const index = addReleaseToQueue(
      release,
      PlayType.Now,
      QueueItemQueueType.User,
      startAt,
    )
    const uri = release.metadata.properties.files[startAt].uri
      .replace('www.', '')
      .replace('arweave.net', 'gateway.irys.xyz')
    play(uri)
    activeIndexRef.current = index

    if (!bypassRecommendations) {
      loadRecommendedForRelease(release)
    }
  }

  const moveQueueItem = (from: number, to: number) => {
    const newQueue = [...queueRef.current]
    const [removed] = newQueue.splice(from, 1)
    newQueue.splice(to, 0, removed)
    queueRef.current = newQueue
  }

  //initial play
  const play = async (uri: string, overrideEvent: boolean = false) => {
    console.log('play', uri)
    console.log('activeIndexRef.current', activeIndexRef.current)
    if (activeIndexRef.current === -1) {
      activeIndexRef.current = 0
    }

    if (audioPlayerRef.current) {
      if (audioPlayerRef.current.src !== uri) {
        audioPlayerRef.current.src = uri
      }
      try {
        await audioPlayerRef.current.play()
      } catch (error) {
        console.error('Error playing track:', error)
        if (!audioPlayerRef.current?.src.includes('gateway.irys.xyz')) {
          tryAlternateAudioSource()
        }
      }
      handleDurationUpdate()
      startTimer()

      if (broadcastChannel) {
        broadcastChannel.postMessage({ type: 'updateTabPlaying' })
      }

      if (!overrideEvent) {
        addPlayEvent(
          activeQueueItem()!.release.publicKey,
          activeQueueItem()!.trackNumber,
          activeQueueItem()!.type === QueueItemQueueType.Recommendation
            ? PlayEventType.PlayAuto
            : PlayEventType.PlayUser,
        )
      }
      updateMediaSession()
    }
  }

  const handleDurationUpdate = () => {
    if (activeQueueItem()) {
      setDuration(
        activeQueueItem()!.release.metadata.properties.files[
          activeQueueItem()!.trackNumber - 1
        ]?.duration,
      )
    }
  }

  //used to pause/resume on one track
  const pauseResume = (forcePause = false) => {
    if (audioPlayerRef.current) {
      if (forcePause) {
        addPlayEvent(
          activeQueueItem()!.release.publicKey,
          activeQueueItem()!.trackNumber,
          PlayEventType.Pause,
        )
        audioPlayerRef.current.pause()
        clearInterval(intervalRef.current)
      } else if (
        (activeQueueItem() && !audioPlayerRef.current.src) ||
        audioPlayerRef.current.paused
      ) {
        const uri = activeQueueItem()!
          .release.metadata.properties.files[
            activeQueueItem()!.trackNumber - 1
          ].uri.replace('www.', '')
          .replace('arweave.net', 'gateway.irys.xyz')
        if (currentTime > 0) {
          addPlayEvent(
            activeQueueItem()!.release.publicKey,
            activeQueueItem()!.trackNumber,
            PlayEventType.Resume,
          )
        }
        play(uri, currentTime > 0)
      } else {
        addPlayEvent(
          activeQueueItem()!.release.publicKey,
          activeQueueItem()!.trackNumber,
          PlayEventType.Pause,
        )

        audioPlayerRef.current.pause()
        clearInterval(intervalRef.current)
      }
    }
  }
  const handleSeekEvent = useCallback(
    throttle(
      () => {
        addPlayEvent(
          activeQueueItem()!.release.publicKey,
          activeQueueItem()!.trackNumber,
          PlayEventType.Seek,
        )
      },
      1000,
      {
        leading: false,
        trailing: true,
      },
    ),
    [],
  )

  const handleSeek = (value: number) => {
    if (audioPlayerRef.current) {
      handleSeekEvent()
      audioPlayerRef.current.currentTime = value
      setCurrentTime(value)
    }
  }

  const handleDrawerToggle = () => {
    //this locks / unlocks the main body scroll when drawer is open
    if (isMedDown) {
      document.body.classList.toggle('overflow-hidden')
      document.body.classList.toggle('max-h-[100dvh]')
    }

    setDrawerOpen(!drawerOpen)
  }

  const tryAlternateAudioSource = throttle(() => {
    if (
      activeQueueItem() &&
      audioPlayerRef.current?.src.includes('gateway.irys.xyz')
    ) {
      const uri = activeQueueItem()!.release.metadata.properties.files[
        activeQueueItem()!.trackNumber - 1
      ].uri.replace('gateway.irys.xyz', 'ar-io.net')

      play(uri)
    } else if (
      activeQueueItem() &&
      audioPlayerRef.current?.src.includes('ar-io.net')
    ) {
      const uri = activeQueueItem()!.release.metadata.properties.files[
        activeQueueItem()!.trackNumber - 1
      ].uri.replace('ar-io.net', 'arweave.net')

      play(uri)
    }
  }, 250)

  return (
    <AudioContext.Provider
      value={{
        activeIndex: activeIndexRef.current,
        activeQueueItem,
        audioPlayerRef,
        currentTime,
        setCurrentTime,
        duration,
        queue: queueRef.current,
        isPlaying,
        setIsPlaying,
        addTrackToQueue,
        addReleaseToQueue,
        addMultipleReleasesToQueue,
        pauseResume,
        playNextQueueItem,
        playPreviousQueueItem,
        playQueueItemAtIndex,
        playTrack,
        playRelease,
        clearQueue,
        moveQueueItem,
        handleSeek,
        history,
        drawerOpen,
        setDrawerOpen,
        handleDrawerToggle,
        fetchInitialQueueFromNinasPicks,
        fetchInitialQueueFromHub,
        fetchInitialQueueFromProfile,
        fetchInitialQueueForRelease,
        fetchInitialQueueForPost,
        activeItem,
      }}
    >
      {children}
    </AudioContext.Provider>
  )
}

export default {
  Context: AudioContext,
  Provider: AudioContextProvider,
}
