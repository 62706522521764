import { ContentNodeType, ReleaseRecommendation } from '@/lib/types'
import shuffleArray from '../utils/shuffleArray'
import { fetchReleasesForHub } from './fetchReleasesForHub'

type FetchRecommendedReleasesForReleaseProps = {
  publicKey: string
}

export const fetchRecommendedReleasesForRelease = async ({
  publicKey,
}: FetchRecommendedReleasesForReleaseProps): Promise<
  ReleaseRecommendation[]
> => {
  let recommendations: ReleaseRecommendation[] = []

  if (publicKey) {
    try {
      const recommendationsResponse = await fetch(
        `${process.env.NINA_RECOMMENDATIONS_ENDPOINT}/release/${publicKey}`,
        { next: { revalidate: 3600 } }, // get new recommendations every hour
      )

      const recommendationsResponseData = await recommendationsResponse.json()
      recommendations = recommendationsResponseData.data.recommendations.map(
        (recommendation: ReleaseRecommendation) => {
          recommendation.type = ContentNodeType.Release
          recommendation.datetime = recommendation.metadata.properties.date

          return recommendation
        },
      )
    } catch (error) {
      console.error('Error fetching recommended releases:', error)
    }
  }

  // get nina's picks if no recommendations
  if (recommendations.length === 0 || publicKey === undefined) {
    const { releases } = await fetchReleasesForHub({
      publicKey: 'ninas-picks',
      pagination: { random: true, limit: 5 },
      withAccountData: true,
    })

    if (releases.length > 0) {
      shuffleArray(releases)
      recommendations = releases.slice(0, 6)
    }
  }

  return recommendations
}
