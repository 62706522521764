import { PlayEventType, PublicKeyString } from '../types'
import initSdk from '../utils/initSdk'

type PlayEvent = {
  track: number
  ninaId: string
  type: string
  releasePublicKey: PublicKeyString
  message?: string
  signature?: string
  publicKey?: string
}

export const addPlayEvent = async (
  releasePublicKey: PublicKeyString,
  track: number,
  type: PlayEventType,
) => {
  await initSdk()
  const sessionSignature = localStorage.getItem('sessionSignature')
  let ninaId = localStorage.getItem('ninaId')

  if (!ninaId) {
    ninaId = ''
  }

  const body: PlayEvent = {
    track,
    ninaId,
    type,
    releasePublicKey,
  }

  if (sessionSignature) {
    const { message, signature, publicKey } = JSON.parse(sessionSignature)
    body.message = message
    body.signature = signature
    body.publicKey = publicKey
  }

  const playRequest = await fetch(`${process.env.NINA_ID_ENDPOINT}/plays`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  return (await playRequest.json()) as { success: boolean }
}
