import ImgixClient from '@imgix/js-core'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getImage = (url: string, width = 400, date?: Date) => {
  url = url.replace('www.', '').replace('arweave.net', 'gateway.irys.xyz')

  if (!url?.includes('http')) return url

  let image = url
  const now = new Date().valueOf()
  const then = date ? new Date(date).valueOf() : 0

  if (!date || (now - then) / 1000 > 120) {
    const client = new ImgixClient({
      domain: process.env.IMGIX_URL!,
      secureURLToken: process.env.IMGIX_TOKEN,
    })

    image = client.buildURL(url, {
      width,
      fm: 'webp',
    })
  }

  image.concat(`?auto=compress,format`)

  return image
}
