import { Post } from '../types'

export const blockedPosts = [
  'DmvdrVaZy4EVNjtAa1EmbpdYf16uLThA455YrtAtPQFM', //articles/nina-mix-eera
]

export const filterBlockedPosts = (posts: Post[]) => {
  return posts.filter((post) => {
    if (blockedPosts.indexOf(post.publicKey) > -1) {
      return
    }

    return post
  })
}
