'use client'

import Nina from '@nina-protocol/js-sdk'
import axios, { AxiosResponse } from 'axios'
import { createContext, useContext, useState } from 'react'
import Wallet from '@/contexts/WalletContext'
import { fetchPost } from '@/lib/post/fetchPost'
import { revalidateEditorialFeed } from '@/lib/post/revalidateEditorialFeed'
import updateMetadata from '@/lib/release/updateMetadata'
import {
  ArticleCreateFormFieldData,
  Hub,
  HubCreateFormFieldData,
  HubUpdateFormFieldData, // NinaProgramAction,
  PendingUpload,
  ReleaseCreateFormFieldData,
  ReleaseUpdateFormFieldData,
} from '@/lib/types'
// import { depositNinaCredit } from '@/lib/utils/depositNinaCredit'
import { logEvent } from '@/lib/utils/event'

// import initSdk from '@/lib/utils/initSdk'

interface FileServiceContextValues {
  createPost: (post: ArticleCreateFormFieldData) => void
  createRelease: (release: ReleaseCreateFormFieldData) => void
  updateRelease: (
    release: ReleaseUpdateFormFieldData,
    releasePublicKey: string,
  ) => void
  createHub: (
    hub: HubCreateFormFieldData,
  ) => Promise<{ error?: string; hub?: Hub }>

  updateHub: (
    handle: string,
    data: HubUpdateFormFieldData,
  ) => Promise<{ error?: string; hub?: Hub }>

  pendingUpload?: PendingUpload
  clearPendingUpload: (delay?: boolean) => void
}

export type Error = {
  message: string
}

export type CreateHubResponse = {
  error?: string
  hub?: Hub
}

export type UpdateHubResponse = {
  error?: string
  hub?: Hub
}

const FileServiceContext = createContext<FileServiceContextValues>({
  createPost: () => undefined,
  createRelease: () => undefined,
  updateRelease: () => undefined,
  createHub: async () => {
    try {
      // Your code to create a hub goes here
      const hub: Hub = {} as Hub

      return { hub }
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      return { error: error.message }
    }
  },
  updateHub: async () => {
    try {
      // Your code to create a hub goes here
      const hub: Hub = {} as Hub

      return { hub }
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      return { error: error.message }
    }
  },
  pendingUpload: undefined,
  clearPendingUpload: () => undefined,
})

const FileServiceContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [pendingUpload, setPendingUpload] = useState<PendingUpload | undefined>(
    undefined,
  )

  const clearPendingUpload = (delay = false) => {
    if (delay) {
      setTimeout(() => {
        setPendingUpload(undefined)
      }, 1500)

      return
    } else {
      setPendingUpload(undefined)
    }
  }

  const { sessionSignature, wallet } = useContext(Wallet.Context)

  const createRelease = async (releaseFormData: ReleaseCreateFormFieldData) => {
    try {
      if (pendingUpload) {
        if (
          pendingUpload.status === 'done' ||
          pendingUpload.status === 'error' ||
          pendingUpload.status === 'scheduled'
        ) {
          clearPendingUpload()
        } else {
          throw new Error('Upload already in progress')
        }
      }

      if (!sessionSignature) {
        throw new Error('Session signature not found')
      }

      const { message, signature, publicKey } = sessionSignature

      if (publicKey !== wallet.publicKey?.toBase58()) {
        throw new Error('Public key mismatch')
      }

      logEvent('release_init_initiated', 'engagement', wallet)

      const pendingRelease: PendingUpload = {
        type: 'release',
        date: new Date().toISOString(),
        status: 'preparing',
        imagePreview: releaseFormData.artwork.preview,
        title: releaseFormData.title,
      }

      setPendingUpload({ ...pendingRelease })

      const trackMap: {
        [key: string]: {
          trackNumber: number
          title: string
        }
      } = {}

      for (const track of releaseFormData.tracks) {
        trackMap[track.name] = {
          trackNumber: track.trackNumber || 1,
          title:
            track.title ||
            `${releaseFormData.title}${
              releaseFormData.tracks.length > 1 ? ` - ${track.trackNumber}` : ''
            }`,
        }
      }

      const formData = new FormData()
      formData.append('title', releaseFormData.title)
      formData.append('description', releaseFormData.description)
      formData.append('catalogNumber', releaseFormData.catalogNumber)
      formData.append('retailPrice', `${releaseFormData.retailPrice}`)
      formData.append('resalePercentage', '5')
      formData.append(
        'amount',
        `${releaseFormData.amount ? releaseFormData.amount : -1}`,
      )
      formData.append(
        'isOpen',
        `${releaseFormData.amount === -1 ? true : false}`,
      )
      formData.append('isScheduled', releaseFormData.date)
      formData.append('isUsdc', `${releaseFormData.isUsdc}`)
      formData.append('tags', JSON.stringify(releaseFormData.tags))

      if (releaseFormData.hub) {
        formData.append('hub', releaseFormData.hub)
      }

      formData.append('message', message)
      formData.append('signature', signature)
      formData.append('publicKey', publicKey)
      formData.append('trackMap', JSON.stringify(trackMap))
      formData.append('artwork', releaseFormData.artwork.file)
      for await (const track of releaseFormData.tracks) {
        formData.append('audio', track)
      }

      // await addCreditsIfNeeded(
      //   [releaseFormData.artwork.file, ...releaseFormData.tracks],
      //   releaseFormData.hub
      //     ? NinaProgramAction.RELEASE_INIT_VIA_HUB
      //     : NinaProgramAction.RELEASE_INIT_WITH_CREDIT,
      //   pendingRelease,
      // )

      const response: AxiosResponse = await axios.post(
        `${process.env.NINA_FILE_SERVICE_ENDPOINT}/release`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.bytes) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total!,
              )

              pendingRelease.status = 'uploading'
              pendingRelease.uploadProgress = percentCompleted
              pendingRelease.estimated = progressEvent.estimated
              pendingRelease.total = progressEvent.total

              if (percentCompleted === 100) {
                pendingRelease.status = 'processing'
              }

              setPendingUpload({ ...pendingRelease })
            }
          },
        },
      )

      const data = response.data

      if (data.status === 'failed') {
        if (pendingUpload) {
          pendingUpload.status = 'error'
          pendingUpload.message = data.message.replace('Error: ', '')
          setPendingUpload({ ...pendingUpload })
        }

        return
      }

      let processing = true
      while (processing) {
        const request = await axios.get(
          `${process.env.NINA_FILE_SERVICE_ENDPOINT}/release/${data.releaseUUID}`,
        )

        if (request.data.status === 'published') {
          pendingRelease.status = 'done'
          pendingRelease.publicKey = request.data.releasePublicKey
          pendingRelease.slug = request.data.release.slug
          setPendingUpload({ ...pendingRelease })
          logEvent('release_init_success', 'engagement', wallet, {
            publicKey: data.releasePublicKey,
          })
          processing = false
        } else if (request.data.status === 'failed') {
          pendingRelease.status = 'error'
          pendingRelease.message = request.data.message?.replace('Error: ', '')
          setPendingUpload({ ...pendingRelease })
          logEvent('release_init_failure', 'engagement', wallet)
          processing = false
        } else if (request.data.status === 'scheduled') {
          pendingRelease.status = 'scheduled'
          setPendingUpload({ ...pendingRelease })
          logEvent('release_init_scheduled_success', 'engagement', wallet)
          processing = false
        }

        if (processing) {
          await new Promise((resolve) => setTimeout(resolve, 1000))
        }
      }
    } catch (error) {
      console.warn('error', error)
      logEvent('release_init_failure', 'engagement', wallet)

      if (pendingUpload) {
        pendingUpload.status = 'error'
        setPendingUpload({ ...pendingUpload })
      }
    }
  }

  const updateRelease = async (
    releaseFormData: ReleaseUpdateFormFieldData,
    releasePublicKey: string,
  ) => {
    try {
      if (pendingUpload) {
        if (
          pendingUpload.status === 'done' ||
          pendingUpload.status === 'error' ||
          pendingUpload.status === 'scheduled'
        ) {
          clearPendingUpload()
        } else {
          throw new Error('Upload already in progress')
        }
      }

      if (!sessionSignature) {
        throw new Error('Session signature not found')
      }

      const { message, signature, publicKey } = sessionSignature

      if (publicKey !== wallet.publicKey?.toBase58()) {
        throw new Error('Public key mismatch')
      }

      logEvent('release_update_initiated', 'engagement', wallet)

      const { release } = await Nina.Release.fetch(releasePublicKey)

      const pendingRelease: PendingUpload = {
        type: 'release',
        date: new Date().toISOString(),
        status: 'preparing',
        imagePreview: releaseFormData.artwork?.croppedImage?.preview || '',
        title: releaseFormData.title,
        slug: release.slug,
      }

      setPendingUpload({ ...pendingRelease })

      const trackMap: {
        [key: string]:
          | {
              title: string
              trackNumber: number
            }
          | {
              uri: string
              track: number
              track_title: string
              duration: number
              type: string
            }
      } = {}

      for (const track of releaseFormData.tracks) {
        if (track.uri) {
          trackMap[track.uri] = {
            uri: track.uri,
            track: track.track || 1,
            duration: track.duration,
            title: track.track_title,
            track_title:
              track.track_title ||
              track.title ||
              `${releaseFormData.title}${
                releaseFormData.tracks.length > 1 ? ` - ${track.track}` : ''
              }`,
            type: track.type,
          }
        } else {
          trackMap[track.fileName] = {
            trackNumber: track.trackNumber || 1,
            title:
              track.track_title ||
              track.title ||
              `${releaseFormData.title}${
                releaseFormData.tracks.length > 1
                  ? ` - ${track.trackNumber}`
                  : ''
              }`,
          }
        }
      }

      const formData = new FormData()
      formData.append('title', releaseFormData.title)
      formData.append('description', releaseFormData.description)
      formData.append('catalogNumber', releaseFormData.catalogNumber)
      formData.append('tags', JSON.stringify(releaseFormData.tags))

      formData.append('message', message)
      formData.append('signature', signature)
      formData.append('publicKey', publicKey)
      formData.append('trackMap', JSON.stringify(trackMap))

      const artworkFile =
        releaseFormData.artwork?.croppedImage?.file ||
        releaseFormData.artwork?.file

      if (artworkFile) {
        if (artworkFile instanceof File || artworkFile instanceof Blob) {
          formData.append('artwork', artworkFile)
        } else {
          formData.append('artwork', '')
        }
      }

      for (const track of releaseFormData.audio) {
        formData.append('audio', track)
      }

      const response: AxiosResponse = await axios.put(
        `${process.env.NINA_FILE_SERVICE_ENDPOINT}/release/${releasePublicKey}`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.bytes) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total!,
              )

              pendingRelease.status = 'uploading'
              pendingRelease.uploadProgress = percentCompleted
              pendingRelease.estimated = progressEvent.estimated
              pendingRelease.total = progressEvent.total

              if (percentCompleted === 100) {
                pendingRelease.status = 'processing'
              }

              setPendingUpload({ ...pendingRelease })
            }
          },
        },
      )

      const data = response.data

      // if (data.release && pendingRelease) {
      //   pendingRelease.status = 'done'
      //   pendingRelease.publicKey = data.release.publicKey
      //   setPendingUpload({ ...pendingRelease })
      //   revalidatePath(`/releases/${data.release.slug}`)
      //   return
      // }

      // if (data.status === 'failed') {
      //   if (pendingRelease) {
      //     pendingRelease.status = 'error'
      //     pendingRelease.message = data.message.replace('Error: ', '')
      //     setPendingUpload({ ...pendingRelease })
      //   }

      //   return
      // }

      let processing = true
      while (processing) {
        const request = await axios.get(
          `${process.env.NINA_FILE_SERVICE_ENDPOINT}/release/${releasePublicKey}`,
        )

        if (request.data.status === 'published') {
          const { release } = await Nina.Release.fetch(
            request.data.releasePublicKey,
          )

          updateMetadata(release.slug)
          pendingRelease.status = 'done'
          pendingRelease.publicKey = request.data.releasePublicKey
          pendingRelease.slug = release.slug
          setPendingUpload({ ...pendingRelease })
          logEvent('release_update_success', 'engagement', wallet, {
            publicKey: data.releasePublicKey,
          })
          processing = false
        } else if (request.data.status === 'failed') {
          pendingRelease.status = 'error'
          pendingRelease.message = request.data.message?.replace('Error: ', '')
          setPendingUpload({ ...pendingRelease })
          logEvent('release_update_failure', 'engagement', wallet)
          processing = false
        }

        if (processing) {
          await new Promise((resolve) => setTimeout(resolve, 1000))
        }
      }
    } catch (error) {
      console.warn('error', error)
      logEvent('release_update_failure', 'engagement', wallet)

      if (pendingUpload) {
        pendingUpload.status = 'error'
        setPendingUpload({ ...pendingUpload })
      }
    }
  }

  const createPost = async (postFormData: ArticleCreateFormFieldData) => {
    const {
      title,
      blocks,
      heroImage,
      subhead,
      description,
      date,
      tags,
      hub,
      author,
    } = postFormData

    try {
      if (!sessionSignature) {
        throw new Error('Session signature not found')
      }

      if (pendingUpload) {
        if (pendingUpload.status === 'done') {
          clearPendingUpload()
        } else {
          throw new Error('Upload already in progress')
        }
      }

      const { message, signature, publicKey } = sessionSignature

      if (publicKey !== wallet.publicKey?.toBase58()) {
        throw new Error('Public key mismatch')
      }

      logEvent('post_init_initiated', 'engagement', wallet)

      const pendingPost: PendingUpload = {
        type: 'post',
        date,
        status: 'uploading',
        imagePreview: heroImage?.preview || '',
        title: postFormData.title,
      }

      setPendingUpload(pendingPost)

      const images = []

      const imageMap: {
        [key: string]: {
          altText: string
          caption: string
          blockIndex: number
        }
      } = {}

      for (const block of blocks) {
        if (block.type === 'image') {
          images.push(block.data.image.file)
          imageMap[block.data.image.file.name] = {
            altText: block.data.altText,
            caption: block.data.caption,
            blockIndex: block.index,
          }
        }
      }
      let slug = title

      if (title.length > 200) {
        slug = title.substring(0, 200)
      }

      slug = slug
        .normalize('NFKD')
        .replace(/[\u0300-\u036F]/g, '') // remove accents and convert to closest ascii equivalent
        .toLowerCase() // convert to lowercase
        .replace('-', '') // remove hyphens
        .replace(/  +/g, ' ') // remove spaces
        .replace(/ /g, '-') // replace spaces with hyphens
        .replace(/[^a-zA-Z0-9-]/g, '') // remove non-alphanumeric characters
        .replace(/--+/g, '') // remove spaces
        .replace(/-$/, '') // remove trailing hyphens

      const formData = new FormData()
      formData.append('title', title)
      formData.append('subhead', subhead)
      formData.append('description', description)
      formData.append('date', date)
      formData.append('tags', JSON.stringify(tags))
      formData.append('blocks', JSON.stringify(blocks))
      formData.append('imageMap', JSON.stringify(imageMap))
      formData.append('slug', slug)
      formData.append('hub', hub)
      formData.append('message', message)
      formData.append('signature', signature)
      formData.append('publicKey', publicKey)
      formData.append('author', author)

      if (heroImage && heroImage.file) {
        formData.append('heroImage', heroImage.file)
      }

      for await (const image of images) {
        formData.append('image', image)
      }

      const response: AxiosResponse = await axios.post(
        `${process.env.NINA_FILE_SERVICE_ENDPOINT}/post`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.bytes) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total!,
              )

              pendingPost.uploadProgress = percentCompleted
              pendingPost.estimated = progressEvent.estimated
              pendingPost.total = progressEvent.total

              if (percentCompleted === 100) {
                pendingPost.status = 'processing'
              }

              setPendingUpload(pendingPost)
            }
          },
        },
      )

      const responseData = response.data

      if (responseData.message === 'Post scheduled') {
        pendingPost.status = 'scheduled'

        logEvent('post_init_scheduled_success', 'engagement', wallet)
      } else if (responseData.message === 'Post created successfully') {
        const { post } = await fetchPost(responseData.postPublicKey)
        pendingPost.status = 'done'
        pendingPost.publicKey = post.publicKey
        pendingPost.slug = post.data.slug

        logEvent('post_init_success', 'engagement', wallet, {
          publicKey: post.publicKey,
        })
        revalidateEditorialFeed()
      }

      setPendingUpload(pendingPost)
    } catch (error) {
      console.warn('error', error)

      logEvent('post_init_failure', 'engagement', wallet)
    }
  }

  const createHub = async (data: HubCreateFormFieldData) => {
    try {
      if (!sessionSignature) {
        throw new Error('Session signature not found')
      }

      const { message, signature, publicKey } = sessionSignature

      if (publicKey !== wallet.publicKey?.toBase58()) {
        throw new Error('Public key mismatch')
      }

      logEvent('hub_init_initiated', 'engagement', wallet)

      const { name, permalink, description, image } = data
      const formData = new FormData()
      formData.append('name', name)
      formData.append('handle', permalink)
      formData.append('description', description)
      formData.append('message', message)
      formData.append('signature', signature)
      formData.append('publicKey', publicKey)

      if (image && image.file) {
        formData.append('image', image.file)
      }

      const response: AxiosResponse = await axios.post(
        `${process.env.NINA_FILE_SERVICE_ENDPOINT}/hub`,
        formData,
      )

      logEvent('hub_init_success', 'engagement', wallet, {
        publicKey: permalink,
      })

      const responseData = response.data

      return responseData
    } catch (error: any) {
      console.warn('error', error.response.data.message)
      logEvent('hub_init_failure', 'engagement', wallet)

      return {
        error: error.response.data.message,
      }
    }
  }

  const updateHub = async (handle: string, data: HubUpdateFormFieldData) => {
    try {
      if (!sessionSignature) {
        throw new Error('Session signature not found')
      }

      const { message, signature, publicKey } = sessionSignature

      if (publicKey !== wallet.publicKey?.toBase58()) {
        throw new Error('Public key mismatch')
      }

      logEvent('hub_init_initiated', 'engagement', wallet)

      const { name, description, image } = data
      const formData = new FormData()
      formData.append('name', name)
      formData.append('description', description)
      formData.append('message', message)
      formData.append('signature', signature)
      formData.append('publicKey', publicKey)
      formData.append('handle', handle)

      if (image && image.file) {
        formData.append('image', image.file as string)
      }

      const response: AxiosResponse = await axios.put(
        `${process.env.NINA_FILE_SERVICE_ENDPOINT}/hub/${handle}`,
        formData,
      )

      logEvent('hub_update_success', 'engagement', wallet, {
        publicKey: handle,
      })

      const responseData = response.data

      return responseData
    } catch (error: any) {
      console.warn('error', error.response.data.message)
      logEvent('hub_update_failure', 'engagement', wallet)

      return {
        error: error.response.data.message,
      }
    }
  }

  // const addCreditsIfNeeded = async (
  //   files: File[],
  //   action: NinaProgramAction,
  //   pendingUpload: PendingUpload,
  // ) => {
  //   try {
  //     if (!sessionSignature) {
  //       throw new Error('Session signature not found')
  //     }

  //     if (!connection) {
  //       throw new Error('Connection not found')
  //     }

  //     await initSdk(wallet)

  //     const { credit, error } = (
  //       await axios.get(
  //         `${
  //           process.env.NINA_FILE_SERVICE_ENDPOINT
  //         }/credit?${new URLSearchParams(sessionSignature).toString()}`,
  //       )
  //     ).data

  //     if (error) {
  //       throw new Error(error)
  //     }

  //     if (credit) {
  //       let costForFiles = await Nina.Uploader.costForFiles(files, true)
  //       costForFiles = Math.ceil(costForFiles)
  //       costForFiles += Nina.constructor.uiToNative(
  //         Nina.NinaProgramActionCost[action],
  //         'So11111111111111111111111111111111111111112',
  //       )

  //       const creditBalance =
  //         Number(credit.credit_balance) +
  //         Number(credit.free_credit_balance) -
  //         Number(credit.credit_used)

  //       if (costForFiles > creditBalance - costForFiles) {
  //         pendingUpload.status = 'transferring_credit'
  //         await depositNinaCredit(
  //           wallet,
  //           connection,
  //           Math.abs(creditBalance - costForFiles),
  //         )
  //       }
  //     }
  //   } catch (error) {
  //     console.warn('error', error)

  //     return false
  //   }
  // }

  return (
    <FileServiceContext.Provider
      value={{
        createPost,
        createRelease,
        updateRelease,
        createHub,
        updateHub,
        pendingUpload,
        clearPendingUpload,
      }}
    >
      {children}
    </FileServiceContext.Provider>
  )
}

export default {
  Context: FileServiceContext,
  Provider: FileServiceContextProvider,
}
